import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-analiz',
  templateUrl: './analiz.component.html',
  styleUrls: ['./analiz.component.css']
})
export class AnalizComponent implements OnInit {
  data: Soru[] = [
    {'id': 1, 'a': 'CANLI', 'b': 'MACERAPEREST', 'c': 'ANALİTİK', 'd': 'UYUMLU'},
    {'id': 2, 'a': 'OYUNCU', 'b': 'İKNA EDİCİ', 'c': 'ISRARCI', 'd': 'BARIŞÇIL'},
    {'id': 3, 'a': 'SOSYAL', 'b': 'GÜÇLÜ İRADELİ', 'c': 'ÖZVERİLİ', 'd': 'UYSAL'},
    {'id': 4, 'a': 'İNANDIRICI', 'b': 'REKABETÇİ', 'c': 'DÜŞÜNCELİ', 'd': 'KONTROLLÜ'},
    {'id': 5, 'a': 'HAYAT VEREN', 'b': 'BECERİKLİ', 'c': 'SAYGILI', 'd': 'ÇEKİNGEN'},
    {'id': 6, 'a': 'HAYAT DOLU', 'b': 'KENDİNE GÜVENLİ', 'c': 'DUYARLI', 'd': 'HALİNDEN MEMNUN'},
    {'id': 7, 'a': 'YÖNLENDİRİCİ', 'b': 'OLUMLU', 'c': 'PLANLAYICI', 'd': 'SABIRLI'},
    {'id': 8, 'a': 'SPONTANE', 'b': 'KENDİNDEN EMİN', 'c': 'PROGRAMLI', 'd': 'UTANGAÇ'},
    {'id': 9, 'a': 'İYİMSER', 'b': 'AÇIK SÖZLÜ', 'c': 'DÜZENLİ', 'd': 'NAZİK'},
    {'id': 10, 'a': 'KOMİK', 'b': 'ETKİLİ', 'c': 'SADIK', 'd': 'DOSTÇA DAVRANAN'},
    {'id': 11, 'a': 'HOŞ', 'b': 'CESUR', 'c': 'AYRINTICI', 'd': 'POLİTİK'},
    {'id': 12, 'a': 'NEŞELİ', 'b': 'GÜVENLİ', 'c': 'KÜLTÜRLÜ', 'd': 'TUTARLI'},
    {'id': 13, 'a': 'ESİN KAYNAĞI', 'b': 'BAĞIMSIZ', 'c': 'İDEALİST', 'd': 'ZARARSIZ'},
    {'id': 14, 'a': 'SICAK KANLI', 'b': 'KARARLI', 'c': 'DERİN', 'd': 'İNCE ESPRİLİ'},
    {'id': 15, 'a': 'KOLAY KAYNAŞAN', 'b': 'HAREKETE GEÇİREN', 'c': 'MÜZİKSEVER', 'd': 'ARABULUCU'},
    {'id': 16, 'a': 'KONUŞKAN', 'b': 'AZİMLİ', 'c': 'İNCE DÜŞÜNCELİ', 'd': 'HOŞGÖRÜLÜ'},
    {'id': 17, 'a': 'ENERJİK', 'b': 'LİDER', 'c': 'FANATİK (SADIK)', 'd': 'İYİ DİNLEYİCİ'},
    {'id': 18, 'a': 'ŞİRİN', 'b': 'ŞEF', 'c': 'ORGANİZATÖR', 'd': 'KANAATKAR'},
    {'id': 19, 'a': 'POPULER', 'b': 'ÜRETKEN', 'c': 'MÜKEMMELLİYETÇİ', 'd': 'TATLI'},
    {'id': 20, 'a': 'FIKIR FIKIR', 'b': 'GÖZÜPEK', 'c': 'TERBİYELİ', 'd': 'DENGELİ'},
    {'id': 21, 'a': 'YÜZSÜZ', 'b': 'ZORBA', 'c': 'SIKILGAN', 'd': 'İFADESİZ'},
    {'id': 22, 'a': 'DİSİPLİNSİZ', 'b': 'ANLAYIŞSIZ', 'c': 'AFFETMEYEN', 'd': 'COŞKUSUZ'},
    {'id': 23, 'a': 'KENDİNİ TEKRARLAYAN', 'b': 'KARŞI GELEN', 'c': 'KİNCİ', 'd': 'SUSKUN'},
    {'id': 24, 'a': 'UNUTKAN', 'b': 'DOBRA', 'c': 'TELAŞLI', 'd': 'KORKAK'},
    {'id': 25, 'a': 'LAF KESEN', 'b': 'SABIRSIZ', 'c': 'GÜVENSİZ', 'd': 'KARARSIZ'},
    {'id': 26, 'a': 'NE YAPACAĞI BELİRSİZ', 'b': 'ŞEFKATSİZ', 'c': 'SEVİLMEYEN', 'd': 'YAŞAMDAN KOPUK'},
    {'id': 27, 'a': 'GELİŞİGÜZEL', 'b': 'DİK KAFALI', 'c': 'MÜŞKÜLPESENT', 'd': 'TEREDDÜTLÜ'},
    {'id': 28, 'a': 'GÖZ YUMAN', 'b': 'KİBİRLİ', 'c': 'KÖTÜMSER', 'd': 'RENKSİZ'},
    {'id': 29, 'a': 'KOLAY SİNİRLENEN', 'b': 'İDDİACI', 'c': 'YABANCILAŞMIŞ', 'd': 'AMAÇSIZ'},
    {'id': 30, 'a': 'SAF', 'b': 'KÜSTAH', 'c': 'OLUMSUZ TUTUMLU', 'd': 'KAYITSIZ'},
    {'id': 31, 'a': 'TANINMAK İSTEYEN', 'b': 'İŞKOLİK', 'c': 'YALNIZLIĞA SIĞINAN', 'd': 'ENDİŞELİ'},
    {'id': 32, 'a': 'KONUŞKAN', 'b': 'PATAVATSIZ', 'c': 'FAZLA HASSAS', 'd': 'ÜRETKEN'},
    {'id': 33, 'a': 'DÜZENSİZ', 'b': 'OTORİTER', 'c': 'BUNALIMLI', 'd': 'ŞÜPHECİ'},
    {'id': 34, 'a': 'TUTARSIZ', 'b': 'HOŞGÖRÜSÜZ', 'c': 'İÇE DÖNÜK', 'd': 'UMURSAMAZ'},
    {'id': 35, 'a': 'DAĞINIK', 'b': 'KARAMSAR', 'c': 'GEVELEYEN', 'd': 'İNSAN KULLANAN'},
    {'id': 36, 'a': 'HAVA ATAN', 'b': 'İNATÇI', 'c': 'KUŞKUCU', 'd': 'UYUŞUK'},
    {'id': 37, 'a': 'GÜRÜLTÜCÜ', 'b': 'AMİRANE', 'c': 'YALNIZLIĞI SEVEN', 'd': 'TEMBEL'},
    {'id': 38, 'a': 'KAFASI DAĞINIK', 'b': 'ÖFKELİ', 'c': 'ŞÜPHECİ', 'd': 'AĞIRKANLI'},
    {'id': 39, 'a': 'YERİNDE DURAMAYAN', 'b': 'ACELECİ', 'c': 'İNTİKAMCI', 'd': 'GÖNÜLSÜZ'},
    {'id': 40, 'a': 'DEĞİŞKEN', 'b': 'KURNAZ', 'c': 'TENKİTÇİ (NEGATİF ELEŞTİRİ)', 'd': 'ÖDÜN VEREN'}
  ];
  cevaplar: string[] = [];
  activeSlideIndex: number = 0;
  tamam = false;
  taglar: string[] = [];
  sayilar: Sayilar = new Sayilar();

  constructor() {
  }
  ngOnInit() {
  }
  next(cevap: string, index: number) {
    if (this.activeSlideIndex < 39) {
      this.activeSlideIndex += 1
    }

    this.cevaplar[index] = cevap;
  }

  previous() {
    if (this.activeSlideIndex > 0) {
      this.activeSlideIndex -= 1
    }
  }

  log(event: number) {
  }

  tamamla() {
    this.tamam = true;
    this.cevaplar.map((cevap, index) => {
      switch (cevap) {
        case 'a':
          this.taglar.push(this.data[index]['a']);
          this.sayilar.a += 1;
          break;
        case 'b':
          this.taglar.push(this.data[index]['b']);
          this.sayilar.b += 1;
          break;
        case 'c':
          this.taglar.push(this.data[index]['c']);
          this.sayilar.c += 1;
          break;
        case 'd':
          this.taglar.push(this.data[index]['d']);
          this.sayilar.d += 1;
          break;
      }
    });
  }
}

class Soru {
  public id: number;
  public a: string;
  public b: string;
  public c: string;
  public d: string;
}

class Sayilar {
  public a: number = 0;
  public b: number = 0;
  public c: number = 0;
  public d: number = 0;
}
